import { createStore } from "redux";
import { combineReducers } from "redux-immutable";
import set from "lodash/set";
import has from "lodash/has";

let originalStore = {};

export function combineReducersRecurse(reducers) {
  // If this is a leaf or already combined.
  if (typeof reducers === "function") {
    return reducers;
  }

  // If this is an object of functions, combine reducers.
  if (typeof reducers === "object") {
    let combinedReducers = {};
    const keys = Object.keys(reducers);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      combinedReducers[key] = combineReducersRecurse(reducers[key]);
    }
    return combineReducers(combinedReducers);
  }

  // If we get here we have an invalid item in the reducer path.
  throw new Error({
    message: "Invalid item in reducer tree",
    item: reducers
  });
}

export function createInjectStore(
  initialReducers,
  initState,
  composedEnhancers,
  options
) {
  originalStore = createStore(
    combineReducersRecurse(initialReducers),
    initState,
    composedEnhancers,
    options
  );

  originalStore.injectedReducers = initialReducers;

  return originalStore;
}

export function reloadReducer(key, reducer, store = originalStore) {
  store.replaceReducer(
    combineReducersRecurse({ ...store.injectedReducers, [key]: reducer })
  );
}

export function injectReducer(
  key,
  reducer,
  force = false,
  store = originalStore
) {
  // If already set, do nothing.
  if (!has(store.injectedReducers, key) || force) {
    set(store.injectedReducers, key, reducer);
    store.replaceReducer(combineReducersRecurse(store.injectedReducers));
  }
}

export function replaceReducer(store = originalStore) {
  store.replaceReducer(combineReducersRecurse(store.injectedReducers));
}
