import environment from "../environment";

const getBaseUrl = () => {
  let url = environment.IMAGE_API_URL;
  if (~url.indexOf("localhost")){
    url += '/files/'
  } else {
    url += '/api/v1/image/resize/'
  }
  return url;
}

const getVideoBaseUrl = () => {
  let url = environment.FILE_API_URL;
  if (~url.indexOf("localhost")){
    url += '/files/'
  } else {
    url += '/'
  }
  return url;
}

export const getMainImageUrl = (files, width, height) => {
  if(!(files && files.length > 0)){
    return ''
  }
  const mainFile = files.find(f => f.isMain);
  let mainFileUrl;
  if (mainFile) {
    mainFileUrl = `${getBaseUrl()}${mainFile.url.replace("\\", "/")}`;
  } else {
    mainFileUrl = `${getBaseUrl()}${files[0].url.replace("\\", "/")}`;
  }

  return getImageThumbUrl(mainFileUrl, width, height)
};

export const getMainImageUrlLast = (files, width, height) => {
  if(!(files && files.length>0)){
    return ''
  }
  const mainFile = files.find(f => f.isMain);
  let mainFileUrl;
  if (mainFile) {
    mainFileUrl = `${getBaseUrl()}${mainFile.url.replace("\\", "/")}`;
  } else {

    mainFileUrl = `${getBaseUrl()}${files[files.length-1].url.replace("\\", "/")}`;
  }
  return fullImage(mainFileUrl, width, height)
};

export const getImageUrl = (url, width, height) => {
  return fullImage(`${getBaseUrl()}${url.replace("\\", "/")}`, width, height);
};

export const getImageThumbUrl = (url, width, height) => {
  return cropImage(url, width, height);
};
//  https://thumb.arivilloso.com/api/v1/image/resize/aba0484b066ad36c706c588903864cb6/a64c68f915deb898.jpg
const cropImage = (url, width, height) => {
  let res = `${url}`.replace('api/v1/','').replace('resize/','')
  const index = res.indexOf('/image/')
  if (~index) {
    res = res.substring(0,index+8)+res.substring(index+8).replace('/','-')
    return res
      .replace('/image/', `/crop${width}x${height}/`)
  }
  return res
}
const fullImage = (url, width, height) => {
  let res = `${url}`.replace('api/v1/','').replace('resize/','')
  const index = res.indexOf('/image/')
  if (~index) {
    res = res.substring(0,index+8)+res.substring(index+8).replace('/','-')
    return res
      .replace('/image/', `/${width}x${height}/`)
  }
  return res
}

export const getVideoUrl = (url) => {
  return `${getVideoBaseUrl()}${url.replace("\\", "/")}`;
};

export const getFirstVerticalImageUrl = (files, width, height) => {
  if (!(files && files.length > 0)){
    return ''
  }
  const firstVertical = files.find(f => f.height > f.width);
  let mainFileUrl;
  if (firstVertical) {
    mainFileUrl = `${getBaseUrl()}${firstVertical.url.replace("\\", "/")}`;
  } else {
    mainFileUrl = `${getBaseUrl()}${files[0].url.replace("\\", "/")}`;
  }
  return getImageThumbUrl(mainFileUrl, width, height)
};

export const getFirstVerticalFullImageUrl = (files, width, height) => {
  if (!(files && files.length > 0)){
    return ''
  }
  const firstVertical = files.find(f => f.height > f.width);
  return getImageUrl(firstVertical.url, width, height)
};
