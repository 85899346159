import React from "react";
import trans from "../../../trans"
import HeaderLink from "./HeaderLink";
import { Link } from "react-router-dom";
import injectSheet from "react-jss";
import colors from "./../../style/colors";
import fonts from "./../../style/fonts";
import menuIcon from "../../assets/ico-menu.svg";
import closeIcon from "../../assets/ico-close.svg";
// import logo from "../../assets/logo-black.svg";
import logo from "../../assets/logo-patriot.svg";
import SocialLink from "../common/SocialLink";
import shareIcon from '../../assets/ico-share.svg';
import Share from '../Share';
import Lang from '../../components/Lang';
import Search from "../Search";

const styles = {
  wrapper: {
    "@media (min-width: 992px)": {
      display: "none"
    }
  },
  navbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 60,
    padding: "0 15px"
  },
  brand: {
    "& .link": {
      display: "flex",
      alignItems: 'center',
      "& .logo": {
        background: `url(${logo}) no-repeat`,
        display: 'inline-block',
        // width: 34,
        width: 44,
        // height: 34,
        height: 44,
        marginRight: 15
      },
      "& .title": {
        fontSize: fonts.large,
        color: colors.textMain,
        textTransform: "uppercase",
        fontWeight: 500,
        letterSpacing: 2
      }
    }
  },
  actions: {
    display: "flex",
    alignItems: "center"
  },

  menuIcon: {
    background: `url(${menuIcon}) transparent no-repeat`,
    padding: 0,
    width: 19,
    height: 12,
    cursor: "pointer",
    marginLeft: 20
  },
  closeIcon: {
    background: `url(${closeIcon}) transparent no-repeat`,
    padding: 0,
    width: 15,
    height: 15,
    cursor: "pointer",
    marginLeft: 20
  },
  menu: {
    position: "absolute",
    zIndex: 99,
    top: 60,
    backgroundColor: "rgba(255, 255, 255, 0.98)",
    width: "100%",
    height: "calc(100vh - 60px)",
    padding: "0 15px"
  },
  linksWrapper: {
    backgroundColor: colors.menuBg,
    borderTop: `1px solid ${colors.menuBorder}`,
    borderBottom: `1px solid ${colors.menuBorder}`,
    height: "85%",
    display: "flex",
    alignItems: "center"
  },
  links: {
    width: "100%"
  },
  menuFooter: {
    backgroundImage: `linear-gradient(to bottom, ${
      colors.menuBg
    } 50%, rgba(255, 255, 255, 0.98) 50%)`,
    height: "15%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .lang-list": {
      top: 'inherit',
      bottom: 35
    }
  },
  menuFooterShareBtnWrapper: {
    position: 'relative',
    "& button": {
      backgroundColor: "#ffffff"
    }
  },
  menuFooterShareContainer: {
    position: 'absolute',
    left: 40,
    top: -11,
  },
  menuFooterShareBtn: {
    backgroundImage: `url(${shareIcon})`,
    display: "inline-block",
    backgroundSize: "100% 100%",
    width: 16,
    height: 16
  },
  social: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  socialLinkWrapper: {
    margin: "0 15px"
  }
};

class MenuMobile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      showShareOptions: false
    };

    this.handleToggleMenu = this.handleToggleMenu.bind(this);
  }

  handleToggleMenu() {
    const { openMenu } = this.state;
    this.setState({ openMenu: !openMenu });
  }

  showShareOptions = () => {
    const {showShareOptions} = this.state
    this.setState({showShareOptions: !showShareOptions})
  }

  render() {
    const { current, classes, menuLinks } = this.props;
    const { openMenu, showShareOptions } = this.state;
    return (
      <div className={classes.wrapper}>
        <div className={classes.navbar}>
          <div className={classes.brand}>
            <Link className="link" to="/">
              <span className="logo"/>
              <span className="title">{trans('title')}</span>
            </Link>
          </div>
          <div className={classes.actions}>
            {!openMenu && [
              <Search isMobile key="search" />,
              <button
                className={classes.menuIcon}
                onClick={this.handleToggleMenu}
                key="button"
              />
            ]}
            {openMenu && (
              <button
                className={classes.closeIcon}
                onClick={this.handleToggleMenu}
              />
            )}
          </div>
        </div>
        {openMenu && (
          <div className={classes.menu}>
            <div className={classes.linksWrapper}>
              <div className={classes.links}>
                <HeaderLink
                  to="/"
                  text="Home"
                  current={current}
                  onClick={this.handleToggleMenu}
                />
                {menuLinks.map((link, i) => (
                  <HeaderLink
                    key={i}
                    to={link.to}
                    text={link.text}
                    current={current}
                    onClick={this.handleToggleMenu}
                  />
                ))}
              </div>
            </div>

            <div className={classes.menuFooter}>
              <div className={classes.menuFooterShareBtnWrapper}>
                <button
                  onClick={this.showShareOptions}
                >
                  <span className={classes.menuFooterShareBtn} />
                </button>
                {showShareOptions && (
                  <div
                    className={classes.menuFooterShareContainer}
                  >
                    <Share />
                  </div>
                )}
              </div>
              <div className={classes.social}>
                <div className={classes.socialLinkWrapper}>
                  <SocialLink name="instagramButton" />
                </div>
                <div className={classes.socialLinkWrapper}>
                  <SocialLink name="pinterestButton" />
                </div>
                <div className={classes.socialLinkWrapper}>
                  <SocialLink name="facebookButton" />
                </div>
              </div>

              <Lang />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectSheet(styles)(MenuMobile);
