export default {
  montserrat: "Montserrat",
  lora: "Lora",

  biggest: 79,
  moreGrand: 60,
  grand: 50,
  lessGrand: 40,
  moreBig: 32,
  big: 28,
  largest: 26,
  moreLarge: 22,
  large: 20,
  great: 18,
  lessGreat: 16,
  mainMore: 15,
  main: 14,
  less: 13,
  small: 12
};
