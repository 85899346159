import React from "react";
import injectSheet from "react-jss";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  ViberShareButton,
  EmailShareButton,

  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  PinterestIcon,
  ViberIcon,
  EmailIcon,
} from 'react-share';
import colors from '../../style/colors';

const styles = {
  ShareOptions: {
    display: 'flex',
    padding: "5px",
    backgroundColor: colors.ourStory,
    borderRadius: 4,
    "& .SocialMediaShareButton": {
      margin: '0 5px',
      cursor: "pointer",
      outline: 'none'
    }
  },
};

class Share extends React.PureComponent {
  render() {
    const { classes, url } = this.props;
    return (
      <div className={classes.ShareOptions}>
        <FacebookShareButton url={url}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <TelegramShareButton url={url}>
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <WhatsappShareButton url={url}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <PinterestShareButton url={url} media=" ">
          <PinterestIcon size={32} round={true} />
        </PinterestShareButton>
        <ViberShareButton url={url}>
          <ViberIcon size={32} round={true} />
        </ViberShareButton>
        <EmailShareButton url={url}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </div>
    );
  }
}

export default injectSheet(styles)(Share);
