export default {
  textMain: "#000000",
  textSecondary: "#a9adbe",
  menuBorder: "rgba(169, 173, 190, 0.4)",
  menuBg: "#fdfdfc",
  footerText: "#b8b8b8",
  footerLogo: "#8f8f8f",
  footerBg: "#f2f2f2",
  footerDivider: "rgba(160, 160, 160, 0.22)",
  buttonBorder: "rgba(0, 0, 0, 0.2)",
  carouselOverlay: "#f0e1d4",
  ourStory: "#f9f1ea",
  mainButtonBorder: "rgba(0, 0, 0, 0.14)",
  textThird: "#c0a289",
  textFourth: "#040404",
  bannerBlackout: "rgba(87, 82, 82, 0.5)",
  inputActiveBorder: "rgba(0, 0, 0, 0.34)",
  labelText: "#999999",
  labelActiveText: "#968d87",
  danger: "#ff0042",
  country: "#8e93aa",
  bridesBg: "#d5c3b3",
  viewerBg: "rgba(50, 50, 50, 0.79)"
};
