import base from "./base";
import development from "./development";
import production from "./production";

const CURRENT_ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV;

const env = Object.assign(
  base,
  {
    development,
    production
  }[CURRENT_ENV] || {}
);

export default Object.freeze(env);
