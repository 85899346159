import React from "react";
import { Link } from "react-router-dom";
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from "react-jss";
import immutableProps from '../../../hocs/immutableProps';
import trans from '../../../trans';
import FormInput from "../common/FormInput";
import { getCollectionsAction } from "../../containers/Collections/actions";
import collectionsReducer from "../../containers/Collections/reducer";
import injectReducer from "../../../utils/injectReducer";
import { getFirstVerticalFullImageUrl } from "../../../utils/imageUrl";
import { isTablet } from "../../../utils/device";
import fonts from '../../style/fonts';
import searchIcon from '../../assets/ico-search.svg';
import searchIconHover from '../../assets/ico-search-hover.svg';
import closeIcon from '../../assets/ico-close.svg';
import logo from "../../assets/logo-patriot.svg";
import colors from '../../style/colors';

const styles = {
  button: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: colors.textSecondary,
    "& span": {
      display: "inline-block",
      background: `url(${searchIconHover}) transparent no-repeat`,
      marginRight: 6,
      width: 14,
      height: 14,
      "@media (min-width: 992px)": {
        background: `url(${searchIcon}) transparent no-repeat`,
      }
    },
    "&:hover": {
      color: colors.textMain,
      "& span": {
        background: `url(${searchIconHover}) transparent no-repeat`,
      }
    },
  },
  root: {
    position: "fixed",
    zIndex: 2,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)"
  },
  search: {
    height: 170,
    padding: 20,
    backgroundColor: colors.menuBg,
    "@media (min-width: 992px)": {
      height: 130,
      display: "flex",
      alignItems: "center"
    }
  },
  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "@media (min-width: 992px)": {
      justifyContent: "space-around",
    }
  },
  logo: {
    display: 'inline-block',
    background: `url(${logo}) no-repeat`,
    width: 44,
    height: 44
  },
  inputWrapper: {
    position: "relative",
    width: "100%",
    marginTop: 20,
    "@media (min-width: 992px)": {
      width: 450,
      marginTop: 0
    }
  },
  searchSymbol: {
    background: `url(${searchIcon}) transparent no-repeat`,
    marginRight: 6,
    width: 14,
    height: 14,
    position: "absolute",
    left: 6,
    top: 24
  },
  closeIcon: {
    background: `url(${closeIcon}) transparent no-repeat`,
    padding: 10,
    width: 15,
    height: 15,
    cursor: "pointer",
    backgroundPosition: "center"
  },
  empty: {
    height: "calc(100% - 170px)",
    "@media (min-width: 992px)": {
      height: "calc(100% - 130px)"
    }
  },
  result: {
    height: "calc(100% - 170px)",
    backgroundColor: colors.menuBg,
    overflow: "scroll",
    "@media (min-width: 992px)": {
      padding: 45,
      height: "calc(100% - 130px)"
    }
  },
  dresses: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    '@media (min-width: 600px)': {
      flexDirection: 'row'
    }
  },
  dressContainer: {
    padding: 15,
    width: '100%',
    '@media (min-width: 600px)': {
      width: '50%'
    },
    '@media (min-width: 992px)': {
      width: '33%'
    },
    '@media (min-width: 1200px)': {
      width: '33.33%'
    },
    '@media (min-width: 1550px)': {
      width: '25%'
    }
  },
  dress: {
    cursor: 'pointer',
    '& .photo-wrapper': {
      position: 'relative'
    },
    '& .name': {
      fontSize: fonts.less,
      textTransform: 'uppercase',
      letterSpacing: 3.5,
      color: colors.textSecondary,
      padding: [26, 0, 5, 0],
      '@media (max-width: 767px)': {
        textAlign: 'center',
        padding: [15, 0, 5, 0],
      }
    },
    '& .collection': {
      fontSize: fonts.less,
      color: colors.textSecondary,
      '@media (max-width: 767px)': {
        textAlign: 'center'
      }
    },
    '&:hover': {
      '& .name': {
        color: '#000000'
      },
      '& .collection': {
        color: colors.textThird
      }
    }
  }
};

class Search extends React.PureComponent {
  constructor(props){
    super(props);
    const { onGetDresses, collections, isMobile } = props;
    if (!(collections && collections.length) && !isMobile){
      onGetDresses();
    }
    this.state = {
      showSearch: false,
      text: "",
      result: []
    }
  }

  toggleSearch = () => {
    const { showSearch } = this.state;
    this.setState({ showSearch: !showSearch })
  }

  handleChange = e => {
    const { collections } = this.props;
    const text = e.target.value;
    const result = [];
    if (text.length > 2 || (text && !Number.isNaN(Number(text)))) {
      collections.forEach(c => {
        c.dresses.forEach(d => {
          if (d.name.toLowerCase().includes(text.toLowerCase()) || d.article.toLowerCase().includes(text.toLowerCase())) {
            result.push({ ...d, collection: c.dressCollection.name });
          }
        })
      });
    }

    this.setState({ text, result });
  };

  render() {
    const { classes, isMobile } = this.props;
    const { showSearch, text, result } = this.state;

    if (!showSearch) {
      return (
        <div className={classes.button} onClick={this.toggleSearch}>
          <span />
          {!isMobile && trans('menu.search')}
        </div>
      )
    }

    const searchInput = (
      <div className={classes.inputWrapper}>
        <div className={classes.searchSymbol} />
        <FormInput
          value={text}
          onChange={this.handleChange}
          label={trans('search.label')}
          autoFocus
        />
      </div>
    );

    return (
      <div
        className={classes.root}
        ref={this.setContainerRef}
      >
        <div className={classes.search}>
          <div className={classes.searchRow}>
            <div className={classes.logo} />
            {!isTablet() && searchInput}
            <button
              className={classes.closeIcon}
              onClick={this.toggleSearch}
            />
          </div>
          {isTablet() && searchInput}
        </div>
        {result.length ? (
          <div className={classes.result}>
            <div className={classes.dresses}>
              {result.map((d, i) => {
                return (
                  <div className={classes.dressContainer} key={i}>
                    <Link
                      to={`/dress/${d.article &&
                      d.article.replace(' ', '').replace(/ /gi, '')}`}
                      className={`${classes.dress} `}
                      onClick={() => this.toggleSearch()}
                    >
                      <div className='gradient'>
                        <div className="photo-wrapper">
                          <img
                            src={getFirstVerticalFullImageUrl(d.files,  1920,1080)}
                            alt={d.name}
                            className='photo'
                          />
                        </div>
                      </div>
                      <div className="name">
                        {d.article} {d.name}
                      </div>

                      <div className="collection">
                        {d.collection}
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className={classes.empty} onClick={this.toggleSearch} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  collections: state.getIn(["collections", "items"])
});

const mapDispatchToProps = dispatch => ({
  onGetDresses: data => dispatch(getCollectionsAction(data))
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
  immutableProps,
  injectReducer({ key: "collections", reducer: collectionsReducer }),
  injectSheet(styles)
)(Search);
