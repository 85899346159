import React from "react";
import injectSheet from "react-jss";
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  PINTEREST_LINK,
  SHARE_LINK
} from "../../../../global-constants";
import instagramIcon from "../../../assets/ico-instagram.svg";
import instagramActiveIcon from "../../../assets/ico-instagram-hover.svg";
import facebookIcon from "../../../assets/ico-facebook.svg";
import facebookActiveIcon from "../../../assets/ico-facebook-hover.svg";
import pinterestIcon from "../../../assets/ico-pinterest.svg";
import pinterestActiveIcon from "../../../assets/ico-pinterest-hover.svg";
import shareIcon from "../../../assets/ico-share.svg";

const styles = {
  shareButton: {
    backgroundImage: `url(${shareIcon})`,
    display: "inline-block",
    backgroundSize: "100% 100%",
    width: 16,
    height: 16
  },
  instagramButton: {
    backgroundImage: `url(${instagramIcon})`,
    display: "inline-block",
    backgroundSize: "100% 100%",
    width: 15,
    height: 15,
    "&:hover": {
      backgroundImage: `url(${instagramActiveIcon})`
    },
    "&.bigInMobile": {
      "@media (max-width: 991px)": {
        width: 26,
        height: 26
      }
    }
  },
  pinterestButton: {
    backgroundImage: `url(${pinterestIcon})`,
    display: "inline-block",
    backgroundSize: "100% 100%",
    width: 16,
    height: 16,
    "&:hover": {
      backgroundImage: `url(${pinterestActiveIcon})`
    },
    "&.bigInMobile": {
      "@media (max-width: 991px)": {
        width: 28,
        height: 28
      }
    }
  },
  facebookButton: {
    backgroundImage: `url(${facebookIcon})`,
    display: "inline-block",
    backgroundSize: "100% 100%",
    width: 7,
    height: 16,
    "&:hover": {
      backgroundImage: `url(${facebookActiveIcon})`
    },
    "&.bigInMobile": {
      "@media (max-width: 991px)": {
        width: 14,
        height: 28
      }
    }
  }
};

export default injectSheet(styles)(
  class SocialButton extends React.PureComponent {
    render() {
      const { classes, name, bigInMobile = false } = this.props;
      const link =
        name === "instagramButton"
          ? INSTAGRAM_LINK
          : name === "facebookButton"
          ? FACEBOOK_LINK
          : name === "pinterestButton"
          ? PINTEREST_LINK
          : SHARE_LINK;
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={
            bigInMobile ? `${classes[name]} bigInMobile` : classes[name]
          }
        >
          {" "}
        </a>
      );
    }
  }
);
