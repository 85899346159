import React from "react";
import injectSheet from "react-jss";
import loaderIcon from "../../assets/spinner.svg";

const styles = {
  loaderWrapper: {
    height: "calc(100vh - 440px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (min-width: 992px)": {
      height: "calc(100vh - 366px)"
    }
  },
  loader: {
    width: 50,
    animation: "spin 3s linear infinite"
  }
};

class Loader extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.loaderWrapper}>
        <img src={loaderIcon} className={classes.loader} alt="loading ..." />
      </div>
    );
  }
}

export default injectSheet(styles)(Loader);
