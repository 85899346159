import React from "react";
import injectSheet from "react-jss";
import Menu from "./Menu";
import MenuMobile from "./MenuMobile";

const styles = {};

class Header extends React.PureComponent {
  render() {
    const { current, menuLinks } = this.props;
    return (
      <header id="header">
        <Menu current={current} menuLinks={menuLinks} />
        <MenuMobile current={current} menuLinks={menuLinks} />
      </header>
    );
  }
}

export default injectSheet(styles)(Header);
