import { create } from "apisauce";
import environment from "../environment";
import { S3_PATH } from "../global-constants";

const apiV1 = "api/v1";

const defaultHeaders = {
  Accept: "application/json",
  "Accept-Language": "en"
};
export const getHeaderConfig = token => {
  const headers = {
    ...defaultHeaders
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
};

//const apiMonitor = response => {
//if (response.data && response.data === "Unauthorized") {
//logOut()
//}
//};

export const apiCreate = () => {
  const token = "";
  const api = create({
    baseURL: `${environment.API_URL}/${apiV1}`,
    headers: getHeaderConfig(token)
  });
  //api.addMonitor(apiMonitor)
  return api;
};

export const staticApiCreate = (base) => {
  const api = create({
    baseURL: base || S3_PATH,
    headers: defaultHeaders
  });
  return api;
};
