import React from "react";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import Loadable from "react-loadable";
import { Frontload } from "react-frontload";
import { ConnectedRouter } from "connected-react-router/immutable";
import createStore from "./store";
import { addLocaleData } from "react-intl";
import LanguageProvider from "./app/containers/LanguageProvider";
import enLocaleData from "./translations/en";
import isServer from "./utils/isServer";
import { createBrowserHistory } from "history";
import App from "./app/app";
import "./plyr.css";
import "./index.css";
import { translationMessages } from "./app/containers/LanguageProvider/i18n";

addLocaleData(enLocaleData);
addLocaleData({
  locale: "en-UPPER",
  parentLocale: "en"
});
//console.log("123123123123");
// Create a store and get back itself and its history object
const history = isServer() ? {} : createBrowserHistory();
const store = createStore(history);
// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here

const Application = (
  <Provider store={store}>
    <LanguageProvider messages={translationMessages}>
      <ConnectedRouter history={history}>
        <Frontload noServerRender={true}>
          <App />
        </Frontload>
      </ConnectedRouter>
    </LanguageProvider>
  </Provider>
);

const root = document.querySelector("#root");

if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, root);
}
