import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DashBoard from "../containers/DashBoard/Loadable";
import Collections from "../containers/Collections/Loadable";
import Collection from "../containers/Collection/Loadable";
import Stores from "../containers/Stores/Loadable";
import ForPartners from "../containers/ForPartners/Loadable";
import Contacts from "../containers/Contacts/Loadable";
// import Donate from "../containers/Donate/Loadable";
import Dress from "../containers/Dress/Loadable";
import SizeChart from "../containers/SizeChart/Loadable";
//import News from "../containers/News/Loadable";
//import NewsOne from "../containers/NewsOne/Loadable";
//import Brides from "../containers/Brides/Loadable";
import Sender from "../containers/SendPage/Loadable";
//import NotFound from "./not-found";

export default () => (
  <Switch>
    <Route exact path="/" component={DashBoard} />
    <Route exact path="/collections" component={Collections} />
    <Route exact path="/collection/:slug" component={Collection} />
    <Route exact path="/dress/:article" component={Dress} />
    {/*<Route exact path="/news" component={News} />*/}
    {/*<Route exact path="/news/:alias" component={NewsOne} />*/}
    {/*<Route exact path="/brides" component={Brides} />*/}
    <Route exact path="/stores" component={Stores} />
    <Route exact path="/for-partners" component={ForPartners} />
    <Route exact path="/contacts" component={Contacts} />
    <Route exact path="/size-chart" component={SizeChart} />
    {/*<Route exact path="/donate" component={Donate} />*/}
    <Route exact path="/send" component={Sender} />
    {/*<Route component={NotFound} />*/}
    <Redirect from='*' to='/' />
  </Switch>
);
