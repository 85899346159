import { applyMiddleware, compose } from "redux";
import { createInjectStore, replaceReducer } from "./utils/injectStore";
import { fromJS } from "immutable";
import { routerMiddleware } from "connected-react-router/immutable";
import promiseMiddleware from "redux-promise-middleware";
import reduxFormMiddleware from "./utils/reduxFormMiddleware";
import createReducer from "./reducers";
import isServer from "./utils/isServer";

export default (history, url = "/") => {
  const enhancers = [];

  // Dev tools are helpful
  if (process.env.NODE_ENV === "development" && !isServer()) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }
  const middleware = [
    routerMiddleware(history),
    promiseMiddleware({
      promiseTypeSuffixes: ["START", "SUCCESS", "FAIL"]
    }),
    reduxFormMiddleware
  ];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  // Do we have preloaded state available? Great, save it.
  const initialState = fromJS(!isServer() ? window.__PRELOADED_STATE__ : {});

  // Delete it once we have it stored in a variable
  if (!isServer()) {
    delete window.__PRELOADED_STATE__;
  }
  // Create the store
  const store = createInjectStore(
    createReducer(history),
    initialState,
    composedEnhancers
  );
  if (module.hot) {
    module.hot.accept("./reducers", () => replaceReducer());
  }
  return store;
};
