// The basics
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Routes from "./routes";
import trans from "../trans";

import "./app.css";
// Action creators and helpers
// import { isServer } from "../store";

class ScrollToTop extends Component {
  componentDidUpdate (prevProps) {
    window.scrollTo(0, 0);
  }

  render () {
    return this.props.children;
  }
}

class App extends Component {
  menuLinks = [
    {
      to: "/collections",
      text: trans("menu.collections")
    },
    // {
    //   to: "/news",
    //   text: trans("menu.news")
    // },
    // {
    //   to: "/brides",
    //   text: trans("menu.brides")
    // },
    {
      to: "/for-partners",
      text: trans("menu.partners")
    },
    {
      to: "/stores",
      text: trans("menu.stores")
    },
    {
      to: "/size-chart",
      text: trans("menu.size.chart")
    }
  ];

  render () {
    const footerLinks = this.menuLinks;

    return (
      <div id="app">
        <Header
          current={this.props.location.pathname}
          menuLinks={this.menuLinks}
        />
        <div id="content">
          <ScrollToTop>
            <Routes/>
          </ScrollToTop>
        </div>
        <Footer
          current={this.props.location.pathname}
          menuLinks={footerLinks}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
