import React from "react";
import { Link } from "react-router-dom";
import injectSheet from "react-jss";
import colors from "./../../style/colors";
import fonts from "./../../style/fonts";

const styles = {
  linkWrapper: {
    "@media (max-width: 991px)": {
      textAlign: "center",
      padding: 12,
      fontSize: fonts.large
    }
  },
  link: {
    "@media (max-width: 991px)": {
      color: colors.textMain,
      display: "inline-block",
      width: "100%"
    },
    "@media (min-width: 992px)": {
      color: colors.textSecondary
    },
    "&:hover": {
      color: colors.textMain
    }
  },
  current: {
    "@media (max-width: 991px)": {
      color: colors.textSecondary
    },
    "@media (min-width: 992px)": {
      textDecoration: "underline"
    }
  }
};

class HeaderLink extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handlerClickLink = this.handlerClickLink.bind(this);
  }

  handlerClickLink() {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  }

  render() {
    const { to, text, current, classes } = this.props;
    return (
      <div className={classes.linkWrapper} onClick={this.handlerClickLink}>
        <Link
          to={to}
          className={`${classes.link} ${to === current ? classes.current : ""}`}
        >
          {text}
        </Link>
      </div>
    );
  }
}

export default injectSheet(styles)(HeaderLink);
