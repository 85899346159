import { apiCreate } from "../../../utils/api";
// import isServer from "../../../utils/isServer";

export const getCollectionsApi = data => apiCreate().get("/openApi/dresses", data)
// !isServer() ? new Promise((resolve, reject) =>
//   staticApiCreate().get(`/public/data/dresses.json?t=${+new Date()}`).then((res) => {
//     if (res && res.ok) {
//       resolve(res);
//     } else {
//       apiCreate().get("/openApi/dresses", data).then(resolve).catch(reject);
//     }
//   }).catch(() => {
//     apiCreate().get("/openApi/dresses", data).then(resolve).catch(reject);
//   })
// ) : apiCreate().get("/openApi/dresses", data);

// export const sendBrideFormApi = data =>
//   apiCreate().post("/openApi/send/bride", data);
