
export const isMobile = () => {
	const { innerWidth } = window;
	//return innerWidth < 992;
	return innerWidth < 768;
}

export const isTablet = () => {
	const { innerWidth } = window;
	return innerWidth < 992;
}