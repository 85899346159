import React from "react";
import injectSheet from "react-jss";
import colors from "../../../style/colors";
import fonts from "../../../style/fonts";

const styles = {
  inputFix: {
    position: "relative",
    width: "100%",
    display: "flex",
    height: 64
  },
  label: {
    color: colors.labelText,
    position: "absolute",
    left: 28,
    top: "calc(50% - 10px)",
    fontSize: fonts.lessGreat,
    transition: "font-size 0.3s ease, top .3s ease, left .3s ease",
    "&:hover": {
      cursor: "text"
    }
  },
  input: {
    padding: [15, 0, 0, 28],
    border: `1px solid ${colors.mainButtonBorder}`,
    fontSize: fonts.lessGreat,
    width: "100%",
    outline: "none",
    textAlign: "left",
    fontFamily: fonts.montserrat,
    "&:focus": {
      border: `1px solid ${colors.inputActiveBorder}`,
      "& ~label": {
        top: 12,
        fontSize: fonts.small,
        color: colors.labelActiveText
      }
    }
  },
  inputFixNotEmpty: {
    "& label": {
      top: 12,
      fontSize: fonts.small,
      color: colors.labelActiveText
    }
  },

  error: {
    color: colors.danger,
    position: "absolute",
    left: 28,
    top: 12,
    fontSize: fonts.lessGreat
  }
};

class FormInput extends React.PureComponent {
  componentDidMount() {
    const { autoFocus = false } = this.props;
    if (autoFocus) {
      this.textInput.focus();
    }
  }

  handleEyeIconClick = () => {
    this.textInput.type === "text"
      ? (this.textInput.type = "password")
      : (this.textInput.type = "text");
  };

  render() {
    const {
      classes,
      label,
      error = false,
      value,
      type,
      propsInputStyles = null,
      containerStyles = null,
      ...props
    } = this.props;

    return (
      <div
        className={`${value !== "" ? classes.inputFixNotEmpty : ""} ${
          containerStyles ? containerStyles : ""
        } ${classes.inputFix}`}
      >
        <input
          {...props}
          type={type}
          value={value}
          className={
            propsInputStyles
              ? `${classes.input} ${propsInputStyles}`
              : classes.input
          }
          ref={input => {
            this.textInput = input;
          }}
        />
        {!error && (
          <label
            onClick={() => this.textInput.focus()}
            className={classes.label}
          >
            {label}
          </label>
        )}
        {error && <span className={classes.error}>{error}</span>}
        {type === "password" ? (
          <div onClick={this.handleEyeIconClick}>
            <span className="icon-ico-eye-inactive" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default injectSheet(styles)(FormInput);
