import React from "react";
import { Link } from "react-router-dom";
import injectSheet from "react-jss";
import trans from "../../../trans"
import HeaderLink from "./HeaderLink";
import Lang from '../Lang';
import Search from '../Search';
import colors from "./../../style/colors";
import fonts from "./../../style/fonts";
// import logo from "../../assets/logo-black.svg";
import logo from "../../assets/logo-patriot.svg";

const styles = {
  menu: {
    display: "none",
    alignItems: "center",
    justifyContent: "space-between",
    height: 60,
    padding: "0 60px",
    "@media (min-width: 992px)": {
      display: "flex"
    }
  },
  links: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "30%",
    "& .lang-list": {
      top: 30
    }
  },
  brand: {
    "& .link": {
      display: "flex",
      alignItems: 'center',
      "& .logo": {
        background: `url(${logo}) no-repeat`,
        display: 'inline-block',
        width: 44,
        height: 44,
        marginRight: 15
      },
      "& .title": {
        fontSize: fonts.big,
        color: colors.textMain,
        textTransform: "uppercase",
        fontWeight: 500,
        letterSpacing: 2
      }
    }
  }
};

class Menu extends React.PureComponent {
  render() {
    const { current, classes, menuLinks } = this.props;

    return (
      <div className={classes.menu}>
        <div className={classes.links}>
          {menuLinks.map((link, i) => {
            if (i < 3) {
              return (
                <HeaderLink
                  key={i}
                  to={link.to}
                  text={link.text}
                  current={current}
                />
              );
            }
            return null;
          })}
        </div>
        <div className={classes.brand}>
          <Link className="link" to="/">
            <span className="logo"/>
            <span className="title">{trans('title')}</span>
          </Link>
        </div>
        <div className={classes.links}>
          {menuLinks.map((link, i) => {
            if (i > 2) {
              return (
                <HeaderLink
                  key={i}
                  to={link.to}
                  text={link.text}
                  current={current}
                />
              );
            }
            return null;
          })}

          <Search />
          <Lang />
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(Menu);
