import typeToReducer from "type-to-reducer";
import { fromJS } from "immutable";
import { GET_COLLECTIONS } from "./constants";
import reducerParse from "../../../utils/reducerParse";

const initialState = fromJS({
  items: [],
  // brideForm: null
});

export default typeToReducer(
  {
    [GET_COLLECTIONS]: {
      START: (state, d) => state.set("loading", true),
      SUCCESS: (state, d) =>
        reducerParse(
          d,
          data => state.set("items", fromJS(data)).set("loading", false),
          payload => state.set("loading", false)
        ),
      FAIL: (state, d) => state.set("loading", false)
    },
    // [SEND_BRIDE_FORM]: {
    //   START: (state, d) => state.set("formLoading", true),
    //   SUCCESS: (state, d) =>
    //     reducerParse(d,data => state.set("brideForm", fromJS(data)).set("formLoading", false),
    //       payload => state.set("errors", fromJS(payload.data)).set("formLoading", false)
    //     ),
    //   FAIL: (state, d) => state.set("formLoading", false)
    // }
  },
  initialState
);
