import { createAction } from "redux-actions";
import { GET_COLLECTIONS } from "./constants";
import { getCollectionsApi } from "./api";

export const getCollectionsAction = createAction(
  GET_COLLECTIONS,
  async d => await getCollectionsApi(d)
);

// export const sendBrideFormAction = createAction(
//   SEND_BRIDE_FORM,
//   async d => await sendBrideFormApi(d)
// );
