/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from "connected-react-router/immutable";
import { reducer as formReducer } from "redux-form/immutable";
import globalReducer from "./app/containers/App/reducer";
import collections from "./app/containers/Collections/reducer";
import languageProviderReducer from "./app/containers/LanguageProvider/reducer";

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default history => ({
  router: connectRouter(history),
  global: globalReducer,
  collections,
  form: formReducer,
  language: languageProviderReducer
});
