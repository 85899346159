import React from "react";
import { Link } from "react-router-dom";
import injectSheet from "react-jss";
import fonts from "../../style/fonts";
import colors from "../../style/colors";
import logo from "../../assets/logo-grey.svg";
import emailIcon from "../../assets/ico-email.svg";
import emailActiveIcon from "../../assets/ico-email-hover.svg";
import phoneIcon from "../../assets/ico-phone.svg";
import phoneActiveIcon from "../../assets/ico-phone-hover.svg";
import {
  EMAIL_SALES,
  PHONE_SALES,
  PHONE_SALES_TEXT
} from "../../../global-constants";
import SocialLink from "../common/SocialLink";
import Lang from '../../components/Lang';

const styles = {
  footer: {
    backgroundColor: colors.footerBg,
    padding: "0 32px",
    "@media (min-width: 992px)": {
      padding: "0 60px"
    }
  },
  brand: {
    textAlign: "center",
    padding: "32px 0",
    "@media (min-width: 992px)": {
      padding: "60px 0"
    },
    "& a": {
      fontSize: fonts.large,
      color: colors.footerLogo,
      textTransform: "uppercase",
      fontWeight: 500,
      height: 34,
      letterSpacing: 1.5,
      display: "inline-block",
      background: `url(${logo}) no-repeat`,
      padding: [5, 0, 0, 50]
    }
  },

  footerMenu: {
    display: "none",
    "@media (min-width: 992px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: [0, 60, 60, 60],
      borderBottom: `1px solid ${colors.footerDivider}`
    }
  },
  footerLinkWrapper: {
    textAlign: "center",
    "@media (min-width: 992px)": {
      padding: [0, 15]
    },
    "@media (min-width: 1200px)": {
      padding: [0, 25]
    },
    "& .lang-list": {
      top: 25
    }
  },
  footerLink: {
    color: colors.footerLogo,
    letterSpacing: 1,
    "&:hover": {
      color: colors.textMain
    }
  },
  current: {
    textDecoration: "underline"
  },

  bottomFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: colors.footerText,
    flexDirection: "column",
    "@media (min-width: 992px)": {
      padding: "20px 0",
      flexDirection: "row"
    }
  },
  footerCopyright: {
    display: "flex",
    "@media (max-width: 991px)": {
      order: 3,
      padding: [38, 0]
    }
  },
  footerContacts: {
    display: "flex",
    alignItems: 'center',
    "@media (max-width: 991px)": {
      order: 1,
      flexDirection: "column",
      textAlign: "center",
      paddingBottom: 39
    }
  },
  contactEmail: {
    paddingBottom: 15,
    "@media (min-width: 992px)": {
      paddingRight: 18,
      paddingBottom: 0
    },
    "& a": {
      display: "inline-block",
      background: `url(${emailIcon}) left center no-repeat`,
      padding: [0, 0, 0, 20],
      color: colors.footerText,
      width: "100%",
      "&:hover": {
        color: colors.textMain,
        background: `url(${emailActiveIcon}) left center no-repeat`
      }
    }
  },
  contactPhone: {
    "@media (min-width: 992px)": {
      paddingLeft: 18
    },
    "& a": {
      display: "inline-block",
      background: `url(${phoneIcon}) left center no-repeat`,
      padding: [0, 0, 0, 16],
      color: colors.footerText,
      width: "100%",
      "&:hover": {
        color: colors.textMain,
        background: `url(${phoneActiveIcon}) left center no-repeat`
      }
    }
  },
  footerFollowUs: {
    display: "flex",
    "@media (max-width: 991px)": {
      order: 2,
      width: "100%",
      padding: [32, 0],
      borderBottom: `1px solid ${colors.footerDivider}`,
      borderTop: `1px solid ${colors.footerDivider}`,
      justifyContent: "center"
    }
  },
  footerFollowUsTitle: {
    paddingRight: 10,
    "@media (max-width: 991px)": {
      display: "none"
    }
  },
  footerSocial: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  socialLinkWrapper: {
    margin: [0, 21],
    "@media (min-width: 992px)": {
      margin: "0 12px"
    }
  },
  socialLinkWrapperLast: {
    margin: [0, 21],
    "@media (min-width: 992px)": {
      margin: [0, 0, 0, 12]
    }
  }
};

class Footer extends React.PureComponent {
  render() {
    const { classes, current, menuLinks } = this.props;
    return (
      <footer id="footer" className={classes.footer}>
        <div className={classes.brand}>
          <Link to="/">Ari Villoso</Link>
        </div>
        <div className={classes.footerMenu}>
          {menuLinks.map((link, index) => {
            return (
              <div key={index} className={classes.footerLinkWrapper}>
                <Link
                  to={link.to}
                  className={`${classes.footerLink} ${
                    link.to === current ? classes.current : ""
                  }`}
                >
                  {link.text}
                </Link>
              </div>
            );
          })}
          <div className={classes.footerLinkWrapper}>
            <Lang />
          </div>
        </div>
        <div className={classes.bottomFooter}>
          <div className={classes.footerCopyright}>
            &copy; ARI VILLOSO 2017 - {new Date().getFullYear()}
          </div>
          <div className={classes.footerContacts}>
            <div className={classes.contactEmail}>
              <a
                href={`mailto:${EMAIL_SALES}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {EMAIL_SALES}
              </a>
            </div>
            <div className={classes.contactPhone}>
              <a
                href={`tel:${PHONE_SALES}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {PHONE_SALES_TEXT}
              </a>
            </div>
          </div>
          <div className={classes.footerFollowUs}>
            <div className={classes.footerFollowUsTitle}>FOLLOW US</div>
            <div className={classes.footerSocial}>
              <div className={classes.socialLinkWrapper}>
                <SocialLink name="instagramButton" bigInMobile={true} />
              </div>
              <div className={classes.socialLinkWrapper}>
                <SocialLink name="pinterestButton" bigInMobile={true} />
              </div>
              <div className={classes.socialLinkWrapperLast}>
                <SocialLink name="facebookButton" bigInMobile={true} />
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default injectSheet(styles)(Footer);
