import React from "react";
import injectSheet from "react-jss";
import trans from '../../../trans';
import colors from '../../style/colors';
import fonts from '../../style/fonts';
import langIcon from '../../assets/ico-lang.svg';
import langIconHover from '../../assets/ico-lang-hover.svg';
import {changeLocale} from '../../containers/LanguageProvider/actions'
import { connect } from 'react-redux';
import { compose } from 'redux';
import immutableProps from '../../../hocs/immutableProps';
import {setLocalLanguage} from '../../../utils/localLanguage';
import checkIcon from '../../assets/ico-success-step.svg';

const styles = {
  langBlockWrapper: {
    position: "relative"
  },
  langBtn: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: colors.textSecondary,
    "& span": {
      display: "inline-block",
      background: `url(${langIcon}) transparent no-repeat`,
      marginRight: 6,
      width: 14,
      height: 14
    },
    "&:hover": {
      color: colors.textMain,
      "& span": {
        background: `url(${langIconHover}) transparent no-repeat`,
      }
    }
  },
  langList: {
    padding: 10,
    backgroundColor: '#ffffff',
    borderRadius: 6,
    position: "absolute",
    right: 0,
    border: `1px solid ${colors.ourStory}`,
    zIndex: 1
  },
  langItem: {
    cursor: "pointer",
    color: colors.textSecondary,
    padding: '10px 20px',
    fontSize: fonts.great,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    "&:hover": {
      color: colors.textMain,
      backgroundColor: colors.ourStory
    },
    "& span": {
      display: "inline-block",
      width: 19,
      height: 18,
      marginRight: 10
    },
    "&.current": {
      color: colors.textMain,
      "& span": {
        backgroundImage: `url(${checkIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%'
      }
    }
  }
};

class Lang extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      showLangOptions: false
    }
    this.containerRef = null
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideContainer)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideContainer)
  }

  setContainerRef = node => {
    this.containerRef = node
  }

  handleClickOutsideContainer = event => {
    const {showLangOptions} = this.state
    if (this.containerRef && !this.containerRef.contains(event.target)
      && showLangOptions) {
      this.setState({showLangOptions: !showLangOptions})
    }
  }

  showLangOptions = () => {
    const {showLangOptions} = this.state
    this.setState({showLangOptions: !showLangOptions})
  }

  changeLanguages = (lang) => {
    //console.log(lang)
    const {onChangeLanguage} = this.props
    setLocalLanguage(lang);
    onChangeLanguage(lang)
    this.setState({showLangOptions: false})
  }

  render() {
    const { classes, language } = this.props;
    const {showLangOptions} = this.state;

    return (
      <div
        className={classes.langBlockWrapper}
        ref={this.setContainerRef}
      >
        <div className={classes.langBtn} onClick={this.showLangOptions}>
          <span />
          {trans('language.current')}
        </div>

        {showLangOptions && (
          <div className={`${classes.langList} lang-list`}>
            <div
              className={`${classes.langItem} ${language === 'en' ? 'current' : ''}`}
              onClick={() => this.changeLanguages('en')}
            >
              <span />
              English
            </div>
            <div
              className={`${classes.langItem} ${language === 'uk' ? 'current' : ''}`}
              onClick={() => this.changeLanguages('uk')}
            >
              <span />
              Українська
            </div>
            {/*<div*/}
            {/*  className={`${classes.langItem} ${language === 'ru' ? 'current' : ''}`}*/}
            {/*  onClick={() => this.changeLanguages('ru')}*/}
            {/*>*/}
            {/*  <span />*/}
            {/*  Русский*/}
            {/*</div>*/}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.getIn(["language", "locale"])
});

const mapDispatchToProps = dispatch => ({
  onChangeLanguage: data => dispatch(changeLocale(data))
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
  immutableProps,
  injectSheet(styles)
)(Lang);